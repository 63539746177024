import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Select } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getUserParks } from "../../../store/parks/selectors";
import styles from "./index.module.scss";
import { getCalendarAction } from "../../../store/extraEvents/thunk";
import { EXTRA_EVENTS_CALENDAR_YEARS } from "../../../const";

interface IProps {
  eventId: number | null;
  setEventId: ActionCreatorWithPayload<number | null>;
}

const EventName: React.FC<IProps> = ({ eventId, setEventId }) => {
  const { Option } = Select;
  const parks = useAppSelector(getUserParks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (parks.length === 1) {
      dispatch(
        getCalendarAction({
          event_id: parks[0].id,
          years: EXTRA_EVENTS_CALENDAR_YEARS,
        })
      )
    }
  }, [dispatch, parks])

  const onParkChange = (id: number) => {
    dispatch(setEventId(id));
    dispatch(
      getCalendarAction({
        event_id: id,
        years: EXTRA_EVENTS_CALENDAR_YEARS,
      })
    )
  };

  return (
    <div className={styles.wrapper}>
      <Select
        placeholder="Выберите старт"
        value={eventId}
        onChange={onParkChange}
        // style={{ width: 255 }}
        defaultValue={eventId}
      >
        {parks?.map((park) => (
          <Option key={park.id} value={park.id}>
            {park.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default EventName;
