import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";
import moment from "moment";
import { LOCAL_SETUP } from "./locale";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { DatePickerColors, DATE_FORMAT, EXTRA_EVENTS_DATES } from "../../../const";
import { isChoosableDate, getExtraEventDatesFromCalendar, isExtraDate, isValidDate } from "../../../utils";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { getCalendar } from "../../../store/extraEvents/selector";

moment.locale("ru_RU", LOCAL_SETUP);

type styleType = {
  backgroundColor: string;
  borderRadius: string;
  color: string;
};

interface IProps {
  eventDate: string;
  setEventDate: ActionCreatorWithPayload<string>;
}

const EventDate: React.FC<IProps> = ({ eventDate, setEventDate }) => {
  const dispatch = useAppDispatch();
  const calendar = useAppSelector(getCalendar);

  const extraEventDates = calendar && getExtraEventDatesFromCalendar(calendar);

  if (!eventDate) return null;

  const renderDate = (current: moment.Moment) => {
    // Кастомная функция рендера даты для выбранных дат и суббот
    const style: styleType = {
      backgroundColor: "",
      borderRadius: "50%",
      color: "",
    };

    if (isChoosableDate(current, extraEventDates)) {
      style.borderRadius = "50%";
      style.color = DatePickerColors.TextColor;

      if (isExtraDate(current, EXTRA_EVENTS_DATES)
      ) {
        style.backgroundColor = DatePickerColors.ExtraDateBackgroundColor;
      } else if (moment(current).format(DATE_FORMAT) !== eventDate) {
        style.backgroundColor = DatePickerColors.SaturdayBackgroundColor;
      } else {
        style.backgroundColor = DatePickerColors.EventDayBackgroundColor;
      }
    }

    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  const onDateChange = (
    date: moment.Moment | null //date - объект Moment.
  ): void => {
    if (isValidDate(date, extraEventDates)) {
      dispatch(setEventDate(moment(date).format(DATE_FORMAT)));
    }
  };
  
  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        placeholder="Выберите дату"
        format={DATE_FORMAT}
        value={moment(eventDate || new Date(), DATE_FORMAT)}
        showToday={false}
        allowClear={false}
        onChange={onDateChange}
        dateRender={renderDate}
      />
    </ConfigProvider>
  );
};

export default EventDate;
